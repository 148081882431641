import React from 'react'
import Layout from '../components/layout/Layout';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import RightArrow from '../components/svgs/RightArrow';
import useSessionStorage from '../utils/useSessionStorage';

export default function Login({ data }) {

    const [email, setEmail] = React.useState("")

    const { backgroundImage, login } = data;

    const [authenticated, setAuthenticated] = useSessionStorage('authenticated', false);



    React.useEffect(() => {
        if (authenticated) {
            navigate("/")
        }
    }, [authenticated])

    const handleSubmit = (e) => {
        e.preventDefault();
        const domain = email && email.match(/(?:@([A-z\-\\.]+))/)[1]
        const altDomain = email && email.match(/(?:@([A-z\-\\.]+))/)[0]
        const allowDomain = login.emailPermissions.filter(allowed => {
            return email === allowed ||
                domain === allowed ||
                altDomain === allowed
        }).length > 0

        if (allowDomain) {
            setAuthenticated(true)
        }
    }


    const handleChange = (e) => {
        return setEmail(e.target.value)
    }

    return (
        <Layout noFooter={true} noMenu={true} noStaticHeader={true}>
            <div className="relative max-h-screen overflow-hidden max-w-screen" >
                <Img className="absolute top-0 left-0 z-0 min-h-screen " fluid={backgroundImage.fluid} />
                <div className="absolute top-0 z-10 flex w-screen min-h-screen overflow-hidden">
                    <div className="bg-opacity-0 md:w-1/2">
                    </div>
                    <div className="relative flex flex-col justify-center w-full px-4 text-gray-100 bg-opacity-75 md:w-1/2 max-h-1/2 md:max-h-screen md:py-8 bg-knorr">
                        <p className="self-center max-w-sm mx-auto text-xl text-center">Please enter your email address to access Knorr Packaging Style Guide</p>
                        <form className="w-full max-w-sm px-4 mx-auto" onSubmit={(e) => handleSubmit(e)}>
                            <label htmlFor="email"></label>
                            <input
                                className="block w-full py-1 pl-2 mx-auto mt-2 rounded text-knorr placeholder-knorr"
                                type="email"
                                placeholder="Enter email"
                                onChange={(e) => handleChange(e)}
                            />
                            <button
                                className={`hover:bg-knorr-light hover:text-knorr bg-knorr text-white mt-4
                uppercase rounded justify-center md:justify-start  min-w-full md:min-w-0  px-4 py-1 flex`}>
                                ENTER

                <RightArrow className={`ml-2 self-center fill-current `} />
                            </button>
                        </form>
                    </div>

                </div>
            </div>
        </Layout>
    )
}


export const LoginPageQuery = graphql`
query LoginQuery {
      login: contentfulSiteMetaData(id: {eq: "b7a76ee6-e571-52b7-9e94-35cb5be5fc96"}) {
          emailPermissions
      }
      backgroundImage: contentfulAsset(contentful_id: {eq: "2VaXQG3VHhz3lhpQxvmUxo"}) {
        
        ...GatsbyFluidImageFragment    
    }
  }

`
